import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
import axios from "axios";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/public.less';
import utils from './assets/js/utils.js';
import http from './assets/js/http.js';


import vueFilePreview from 'gxd-file-preview';

//初始化自定义插件，（pdf.js,worker.js文件建议放在本地服务器),cdn有可能不稳定
Vue.use(vueFilePreview,{
    pdf: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.288/build/pdf.min.js', //pdf插件
    worker:'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.288/build/pdf.worker.min.js',//pdf.work插件
});

Vue.prototype.$utils=utils;
Vue.use(http);
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import axios from "axios";
import {Message} from 'element-ui';

const utilshttp = "https://awk.jorto.net/";

//将对象元素转换成字符串以作比较
function obj2key(obj, keys) {
    var n = keys.length,
        key = [];
    while (n--) {
        key.push(obj[keys[n]]);
    }
    return key.join('|');
}

//导出exo
const dowex = (params = {}, url = '', name = '') => {
    const that = this;
    if(localStorage.getItem('isgethttp')){
        params.isgethttp=localStorage.getItem('isgethttp')||'';
    }
    params.token = localStorage.getItem('token');
    params.company_id_admin = localStorage.getItem('company_id_admin') || ''

    const link = document.createElement('a')
    var httpurl = apiurl + url
    axios.post(httpurl, params, {
        responseType: 'arraybuffer',
        headers: {
            token: localStorage.getItem('token')
        }
    }).then(res => {
        var enc = new TextDecoder('utf-8')
        try {
            var resa = JSON.parse(enc.decode(new Uint8Array(res.data))) //转化成json对象
            Message({
                message: '请选择筛选条件后导出',
                type: 'warning'
            });
        } catch (err) {
            // 	// 创建Blob对象，设置文件类型
            let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel"
            })
            let objectUrl = URL.createObjectURL(blob) // 创建URL
            link.href = objectUrl
            link.download = name || '文件' // 自定义文件名
            link.click() // 下载文件
            URL.revokeObjectURL(objectUrl); // 释放内存
        }
    })

}


//去重操作
const uniqeByKeys = (array, keys) => {
    var arr = [];
    var hash = {};
    for (var i = 0, j = array.length; i < j; i++) {
        var k = obj2key(array[i], keys);
        if (!(k in hash)) {
            hash[k] = true;
            arr.push(array[i]);
        }
    }
    return arr;
}

// 是否是邮箱
const isEmail = (s) => {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
// 手机号码
const isMobile = (s) => {
    return /^1[0-9]{10}$/.test(s)
}
// 获取上下20年
const genumyear = (e) => {
    var nfOptionsArray = new Array();
    var years = new Date().getFullYear();
    for (var i = years - (e || 5); i <= years + (e || 10); i++) {
        nfOptionsArray.push(i + '年');
    }
    return nfOptionsArray;
}

//传入年月  获取周
const getWeekTime = (year, month, weekday) => {
    var d = new Date();
    // 该月第一天
    d.setFullYear(year, month - 1, 1);
    var w1 = d.getDay();
    if (w1 == 0) w1 = 7;
    // 该月天数
    d.setFullYear(year, month, 0);
    var dd = d.getDate();
    // 第一个周一
    let d1;
    if (w1 != 1) d1 = 7 - w1 + 2;
    else d1 = 1;
    let week_count = Math.ceil((dd - d1 + 1) / 7);
    const returnweekdata = [];
    for (var i = 0; i < week_count; i++) {
        var monday = d1 + (i) * 7;
        var sunday = monday + 6;
        // var from = year+"-"+month+"-"+monday;
        if (month.length == 1) {
            month = '0' + month
        }
        if (monday < 10) {
            monday = '0' + monday
        }
        var from = month + "." + monday;
        var to;
        if (sunday <= dd) {
            // to = year+"-"+month+"-"+sunday;
            to = month + "." + sunday;
        } else {
            d.setFullYear(year, month - 1, sunday);
            let days = d.getDate();
            if (days < 10) {
                days = '0' + days;
            }
            to = (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) + "." + days;
        }
        const weekdata = '第' + numberToChinese(i + 1) + '周' + '(' + from + "-" + to + ")";
        console.log(from, to, 66666)
        returnweekdata.push(weekdata)
    }
    return returnweekdata;
}

//获取周一的日期
function getMonDate() {
    var d = new Date(),
        day = d.getDay(),
        date = d.getDate();
    if (day == 1) return d;
    if (day == 0) d.setDate(date - 6);
    else d.setDate(date - day + 1);
    return d;
}

//获得周期名字
function getDayName(day) {
    var day = parseInt(day);
    if (isNaN(day) || day < 0 || day > 6) return false;
    var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    return weekday[day];
}

function getMonthWeek(a, b, c) {
    var date = new Date(a, parseInt(b) - 1, c),
        w = date.getDay(),
        d = date.getDate();

    console.log(a, b, c, w, d, (d + 6 - w) / 7, '*-----***')

    return parseInt((d + 6 - w) / 7);
}

//获取每月第几周的周一的日期
function getMondayTime(year, month, weekday) {
    var d = new Date();
    // 该月第一天
    d.setFullYear(year, month - 1, 1);
    var w1 = d.getDay();
    if (w1 == 0) w1 = 7;
    // 该月天数
    d.setFullYear(year, month, 0);
    var dd = d.getDate();
    // 第一个周一
    let d1;
    if (w1 != 1) d1 = 7 - w1 + 2;
    else d1 = 1;
    var monday = d1 + (weekday - 1) * 7;
    return monday;
}

function getWeeks(year, month) {
    var d = new Date();
    // 该月第一天
    d.setFullYear(year, month - 1, 1);
    var w1 = d.getDay();
    if (w1 == 0) w1 = 7;
    // 该月天数
    d.setFullYear(year, month, 0);
    var dd = d.getDate();
    // 第一个周一
    let d1;
    if (w1 != 1) d1 = 7 - w1 + 2;
    else d1 = 1;
    let week_count = Math.ceil((dd - d1 + 1) / 7);
    return week_count;
}


//传入年月日获取当前周
const getNowweektime = () => {
    var state = {};
    var that = this;
    var d = getMonDate();
    var ds = new Date();
    var arr = [];
    for (var i = 0; i < 7; i++) {
        let weekDay = getDayName(d.getDay());
        let date = d.getDate() + '日';
        if (weekDay == '周一') {
            let beginTime = ds.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            state.beginTime = beginTime;
        }
        if (weekDay == '周日') {
            let endTime = ds.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            state.endTime = endTime;
        }
        arr.push({
            weekDay: weekDay,
            date: date
        });
        d.setDate(d.getDate() + 1);
    }
    let month = ds.getMonth() + 1;


    console.log(ds.getFullYear(), month, ds.getDate(), 'oneDateoneDateoneDateoneDate')
    let weeks = getMonthWeek(ds.getFullYear(), month, ds.getDate());
    //每月周一日期
    let oneDate = getMondayTime(ds.getFullYear(), month, 1);
    if (ds.getDate() < oneDate) {
        console.log('命中')
        month = ds.getMonth();
        weeks = getWeeks(ds.getFullYear(), month);
    }
    console.log('month:', month, 'weeks:', weeks);
    var setState = {
        list: arr,
        yearDate: ds.getFullYear(),
        monthDate: month,
        month: ds.getMonth() + 1,
        monthWeek: '第' + (weeks) + '周',
        theMonthWeek: '第' + numberToChinese(weeks) + '周'
    };
    console.log(setState, '66**')
    return '第' + numberToChinese(weeks) + '周';
}


//返回月
const getmonthdata = () => {
    const monthdata = ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月']
    return monthdata
}

//根据年月返回日
const getdaydata = (year, month) => {
    console.log(year, month, '截取')
    if (year && month) {
        var d = new Date(year, month, 0);

    } else {
        var d = new Date();
    }
    const daydata = [];
    for (var i = 0; i < d.getDate(); i++) {
        daydata.push(i + 1 + '日')
    }
    return daydata;
}

// 电话号码
const isPhone = (s) => {
    return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

// that.$utils.getBase64(file.raw).then(res => {
// 				console.log(res, '转换的')
// 			});
// 图片64转码
const getBase64 = (file) => {
    return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
            imgResult = reader.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.onloadend = function () {
            resolve(imgResult);
        };
    });
}
// 拆分数组
const spArr = (arr, num) => { //arr是你要分割的数组，num是以几个为一组
    let newArr = [] //首先创建一个新的空数组。用来存放分割好的数组
    for (let i = 0; i < arr.length;) { //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, i += num));
    }
    return newArr
}

// console.log(spArr(arr, 4))
//通过主键值，从列表数据里筛选出对应的一条数据
const getOne = (data, id_value, id_name) => { // data是一组列表数据，id_value是要筛选主键值，id_name是主键的键名
    let obj = {};
    data.forEach((o, i) => {
        if (id_value == o[id_name]) {
            obj = o;
        }
    });
    return obj;
}

// 图片上传
// imguploadFile = (count,url,action,file) =>
function imguploadFile(count, url, action, file, callback) {
    // count数量  url地址 action：uploadimage  file
    var imgbox = [];
    var formData = {
        token: uni.getStorageSync("token"),
        userid: uni.getStorageSync("userid"),
        action: action || "uploadimage "
    }
    uni.chooseImage({
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        count: count || 1,
        success: chooseImageRes => {
            const tempFilePaths = chooseImageRes.tempFilePaths;
            for (var i = 0; i < tempFilePaths.length; i++) {
                uni.uploadFile({
                    url: url,
                    formData: formData,
                    filePath: tempFilePaths[i],
                    name: file || 'file',
                    method: 'post',
                    success: function (uploadFileRes) {
                        uploadFileRes = JSON.parse(uploadFileRes.data);
                        imgbox.push(uploadFileRes.data);
                        callback(imgbox);
                    }
                });
            }
        },

    });


}


// 是否是空
const isnull = (s) => {
    return s != '' && s != undefined && s != null && s != 'null' && s != 'undefined'
}
//邮政编码
const postal = () => {
    return /[1-9]\d{5}(?!\d)/.test(str);
}
// 随机数范围
const random = (min, max) => {
    if (arguments.length === 2) {
        return Math.floor(min + Math.random() * ((max + 1) - min))
    } else {
        return null;
    }
}

// 随机数
const getstring = (n) => {
    var str = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K',
        'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    var res = "";
    for (var i = 0; i < n; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += str[id];
    }
    return res;
}


// 身份证
const isidcard = (s) => {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(s);
}
// 解析url
const getQueryString = (name) => {
    var url = name.toString(); //当前完整url
    var u = url.split("?"); //以？为分隔符把url转换成字符串数组
    if (typeof (u[1]) == "string") {
        u = u[1].split("&"); //同上
        var get = {};
        for (var i in u) {
            var j = u[i].split("="); //同上
            get[j[0]] = j[1];
        }
        return get;
    } else {
        return {};
    }

}
// 将阿拉伯数字翻译成中文的大写数字
const numberToChinese = (num) => {
    var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
    var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
    var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$")
                    .test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
            re = AA[0] + re;
        if (a[0].charAt(i) != 0)
            re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }

    if (a.length > 1) // 加上小数部分(如果有小数部分)
    {
        re += BB[6];
        for (var i = 0; i < a[1].length; i++)
            re += AA[a[1].charAt(i)];
    }
    if (re == '一十')
        re = "十";
    if (re.match(/^一/) && re.length == 3)
        re = re.replace("一", "");
    return re;
}

// 将数字转换为大写金额
const changeToChinese = (Num) => {
    //判断如果传递进来的不是字符的话转换为字符
    if (typeof Num == "number") {
        Num = new String(Num);
    }
    ;
    Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
    Num = Num.replace(/ /g, "") //替换tomoney()中的空格
    Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
    if (isNaN(Num)) { //验证输入的字符是否为数字
        //alert("请检查小写金额是否正确");
        return "";
    }
    ;
    //字符处理完毕后开始转换，采用前后两部分分别转换
    var part = String(Num).split(".");
    var newchar = "";
    //小数点前进行转化
    for (var i = part[0].length - 1; i >= 0; i--) {
        if (part[0].length > 10) {
            return "";
            //若数量超过拾亿单位，提示
        }
        var tmpnewchar = ""
        var perchar = part[0].charAt(i);
        switch (perchar) {
            case "0":
                tmpnewchar = "零" + tmpnewchar;
                break;
            case "1":
                tmpnewchar = "壹" + tmpnewchar;
                break;
            case "2":
                tmpnewchar = "贰" + tmpnewchar;
                break;
            case "3":
                tmpnewchar = "叁" + tmpnewchar;
                break;
            case "4":
                tmpnewchar = "肆" + tmpnewchar;
                break;
            case "5":
                tmpnewchar = "伍" + tmpnewchar;
                break;
            case "6":
                tmpnewchar = "陆" + tmpnewchar;
                break;
            case "7":
                tmpnewchar = "柒" + tmpnewchar;
                break;
            case "8":
                tmpnewchar = "捌" + tmpnewchar;
                break;
            case "9":
                tmpnewchar = "玖" + tmpnewchar;
                break;
        }
        switch (part[0].length - i - 1) {
            case 0:
                tmpnewchar = tmpnewchar + "元";
                break;
            case 1:
                if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
                break;
            case 2:
                if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
                break;
            case 3:
                if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
                break;
            case 4:
                tmpnewchar = tmpnewchar + "万";
                break;
            case 5:
                if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
                break;
            case 6:
                if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
                break;
            case 7:
                if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
                break;
            case 8:
                tmpnewchar = tmpnewchar + "亿";
                break;
            case 9:
                tmpnewchar = tmpnewchar + "拾";
                break;
        }
        var newchar = tmpnewchar + newchar;
    }
    //小数点之后进行转化
    if (Num.indexOf(".") != -1) {
        if (part[1].length > 2) {
            // alert("小数点之后只能保留两位,系统将自动截断");
            part[1] = part[1].substr(0, 2)
        }
        for (i = 0; i < part[1].length; i++) {
            tmpnewchar = ""
            perchar = part[1].charAt(i)
            switch (perchar) {
                case "0":
                    tmpnewchar = "零" + tmpnewchar;
                    break;
                case "1":
                    tmpnewchar = "壹" + tmpnewchar;
                    break;
                case "2":
                    tmpnewchar = "贰" + tmpnewchar;
                    break;
                case "3":
                    tmpnewchar = "叁" + tmpnewchar;
                    break;
                case "4":
                    tmpnewchar = "肆" + tmpnewchar;
                    break;
                case "5":
                    tmpnewchar = "伍" + tmpnewchar;
                    break;
                case "6":
                    tmpnewchar = "陆" + tmpnewchar;
                    break;
                case "7":
                    tmpnewchar = "柒" + tmpnewchar;
                    break;
                case "8":
                    tmpnewchar = "捌" + tmpnewchar;
                    break;
                case "9":
                    tmpnewchar = "玖" + tmpnewchar;
                    break;
            }
            if (i == 0) tmpnewchar = tmpnewchar + "角";
            if (i == 1) tmpnewchar = tmpnewchar + "分";
            newchar = newchar + tmpnewchar;
        }
    }
    //替换所有无用汉字
    while (newchar.search("零零") != -1)
        newchar = newchar.replace("零零", "零");
    newchar = newchar.replace("零亿", "亿");
    newchar = newchar.replace("亿万", "亿");
    newchar = newchar.replace("零万", "万");
    newchar = newchar.replace("零元", "元");
    newchar = newchar.replace("零角", "");
    newchar = newchar.replace("零分", "");
    if (newchar.charAt(newchar.length - 1) == "元") {
        newchar = newchar + "整"
    }
    return newchar;
}

export default {
 //    isEmail: isEmail,
 //    isMobile: isMobile,
 //    isPhone: isPhone,
 //    isnull: isnull,
 //    getQueryString: getQueryString,
 //    isidcard: isidcard,
 //    random: random,
 //    numberToChinese: numberToChinese,
 //    changeToChinese: changeToChinese,

 //    spArr: spArr,
 //    getOne: getOne,
 //    apiurl: apiurl,
 //    getstring: getstring,
 //    getBase64: getBase64,
 //    genumyear: genumyear,
 //    getmonthdata: getmonthdata,
 //    getdaydata: getdaydata,
 //    getWeekTime: getWeekTime,
 //    getrootbtn,
 //    uniqeByKeys,
 //    getNowweektime,
 //    WebSocketurl,
 //    dowex,
 //    showimgurl,
	// uploadimgurlrck

}

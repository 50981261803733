import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
	return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '/',
		component: () => import( /* 登录 */ '../views/login')
	},
	{
		path: '/home',
		name: '/home',
		component: () => import( /* 首页 */ '../views/layout'),
		children: [{
				path: '/index',
				name: 'index',
				component: () => import( /* 首页 */ '../views/home/index'),
			},
			{
				path: '/curriculum_list',
				name: 'curriculum_list',
				component: () => import( /* 课程列表 */ '../views/home/curriculum_list'),
			},
			{
				path: '/curriculum_details',
				name: 'curriculum_details',
				component: () => import( /* 课程详情 */ '../views/home/curriculum_details'),
			},
			{
				path: '/curriculum_video',
				name: 'curriculum_video',
				component: () => import( /* 加入学习 */ '../views/home/curriculum_video'),
			},
			{
				path: '/examination_list',
				name: 'examination_list',
				component: () => import( /* 考试列表 */ '../views/home/examination_list'),
			},
			{
				path: '/examination_details',
				name: 'examination_details',
				component: () => import( /* 考试详情 */ '../views/home/examination_details'),
			},
			{
				path: '/examination_analysis',
				name: 'examination_analysis',
				component: () => import( /* 错题解析 */ '../views/home/examination_analysis'),
			},
			{
				path: '/knowledge_list',
				name: 'knowledge_list',
				component: () => import( /* 知识库列表 */ '../views/home/knowledge_list'),
			},
			{
				path: '/knowledge_details',
				name: 'knowledge_details',
				component: () => import( /* 知识库详情 */ '../views/home/knowledge_details'),
			},
			{
				path: '/notice_list',
				name: 'notice_list',
				component: () => import( /* 公告列表 */ '../views/home/notice_list'),
			},
			{
				path: '/notice_details',
				name: 'notice_details',
				component: () => import( /* 公告详情 */ '../views/home/notice_details'),
			},
			{
				path: '/news_list',
				name: 'news_list',
				component: () => import( /* 新闻列表 */ '../views/home/news_list'),
			},
			{
				path: '/details',
				name: 'details',
				component: () => import( /* 公告、新闻详情 */ '../views/home/details'),
			},
			/* 个人中心 */
			{
				path: '/my_index',
				name: 'my_index',
				component: () => import( /* 个人中心-个人资料 */ '../views/my/my_index'),
			},
			{
				path: '/my_course',
				name: 'my_course',
				component: () => import( /* 个人中心-我的课程 */ '../views/my/my_course'),
			},
			{
				path: '/my_thetest',
				name: 'my_thetest',
				component: () => import( /* 个人中心-我的考试 */ '../views/my/my_thetest'),
			}
		]
	},
	
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
  // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
})


export default router

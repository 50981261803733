<template>
 <div id="app"  @contextmenu.prevent=""  ondragstart="return false;" onselectstart="return <br>false;" onselect="document.selection.empty();">
 <!-- <div id="app"  > -->
    <router-view/>
  </div>
</template>

<script>
	export default {
		data() {
			return {
				
			};
		},
		created() {
			const that = this;
			that.get_title()
			document.onkeydown = () => {
				  //禁用F12
				  if (window.event && window.event.keyCode == 123) {
					return false;
				  //禁用ctrl+shift+i,
				  } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
					return false;
				  //屏蔽Shift+F10
				  } else if (window.event.shiftKey && window.event.keyCode == 121) {
					return false;
				  }
				};
		},
		methods: {
			get_title(){
				const that = this;
				that.http('/safety/home_public', {
			
				}).then(res => {
					if (res.code == 200) {
						document.title = res.site_name;
					}
				})
			},
		}
	}
</script>

<style lang="less">

</style>
